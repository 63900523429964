/*
This file is part of Meme It So

"Meme It So" is a media (TV show and movies) screen capture and text caption
database and image macro generator.
Copyright (C) 2021  Phillip Dreizen

Meme It So is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Meme It So is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

/* title */
/* free on st-minutiae */
@font-face {
    font-family: "TNG_Title";
    src: url("./fonts/TNG_Title.ttf") format("truetype");
}

/* credits */
/* copy-cat /w free to use license.
 *Note that the letters look great to me, but the punctuation isn't quite right */
@font-face {
    font-family: 'OPTICristetaItalic';
    src: url('./fonts/OPTICristetaItalic.ttf.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* lcars */
/* Paid for license */
@font-face{
    font-family: "Swiss911UltraCompressed";
    src:url("./fonts/Swiss911UltraCompressed.woff2") format("woff2"),
        url("./fonts/Swiss911UltraCompressed.woff") format("woff");
}


@tailwind base;
@tailwind components;

/* TODO: add to tailwindcss config */
.blink {
    animation: blinker 1.0s linear infinite;
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.text-blue-sttng-credits {
    color: #1d7eff;
}

.word-spacing-xl {
    word-spacing: 1rem;
}
.word-spacing-lg {
    word-spacing: .5rem;
}

.break {
  flex-basis: 100%;
  height: 0;
}

@tailwind utilities;


/*
@layer base {
    body {
        @apply text-white bg-gray-900 font-sttng-lcars tracking-widest;
    }
}
*/
